import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: "root",
})
export class HomeService {
    headers: any;
    constructor(private http: HttpClient) {
        this.headers = new HttpHeaders()
            .set("content-type", "application/json")
            .set( "Access-Control-Allow-Headers", "X-Requested-With,content-type,token" )
            .set("X-Content-Type-Options", "nosniff")
            .set("X-XSS-Protection", "1; mode=block")
            .set("X-Frame-Options", "deny")
            .set("Content-Security-Policy", 'default-src "self"')
            .set("Strict-Transport-Security", "max-age=63072000;")
            .set("includeSubDomains", "preload");
    }

    postenquiry(data) {
        return this.http
            .post<any>(`https://sriakshara.in:9988/nodeapp/postenquiry`, data)
            .pipe(map((res) => {}));
    }

    postannadanamhundi(data) {
        return this.http.post(
            `https://kcprmc.com:2105/dashboardapi/postannadanamhundi`,
            data
        );
    }

    vehicleinFunction(data) {
        return this.http.post(
            `https://kcprmc.com:2105/dashboardapi/submitrazorpay`,
            data
        );
    }

    getlastid() {
        return this.http.post(
            `https://kcprmc.com:5762/dashboardapi/getlastid`,
            []
        );
    }

    NodekitPay(data: any) {
        return this.http
            .post<any>(`https://kcprmc.com:5845/ccavRequestHandler`, data, {
                headers: this.headers,
            })
            .pipe(
                map(
                    (res) => {
                        return res;
                    },
                    (error: any) => {
                        return error;
                    }
                )
            );
    }

    getentryticketrate() {
        return this.http.get(
            `https://kcprmc.com:5762/dashboardapi/getentryticketrate`
        );
    }

    addcontactsform(data) {
          return this.http.post(
              `https://kcprmc.com:5762/dashboardapi/addcontactsform`,
              data
          );
    }
}


