<!-- <div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/5.png" alt="contact image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>We Love to <span> Hear from You</span></h2>
                            <p>Feel free and share with us. We will get you</p>
                        </div>

                        <div class="contact-form">
                            <form [formGroup]="enquiryform" (ngSubmit)="enquirysubmit()">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-12 col-sm-6">
                                            <div class="form-group mb-3">
                                                <input type="text" name="name" formControlName="name"
                                                    class="form-control" placeholder="Your Name">
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-sm-6">
                                            <div class="form-group mb-3">
                                                <input type="text" name="number" formControlName="number"
                                                    minlength="10" maxlength="10" (keypress)="numericOnly($event)"
                                                    class="form-control" placeholder="Enter your Mobile Number">
                                            </div>
                                        </div>

                                        <div class="col-lg-12 col-md-12">
                                            <div class="form-group mb-3">
                                                <textarea name="msg" class="form-control" formControlName="msg"
                                                    cols="30" rows="5" placeholder="Your Message"></textarea>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="request-call">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/192 (2).png" alt="logo">
                        </a>
                    </div>
                    <!-- <p>RMC is the civic body that governs the city of Rajamahendravaram in the Indian state of Andhra Pradesh. Constituted as a third grade Municipality in 1865, Rajamahendravaram town has grown fast to the level of selection grade Municipality in the year 1980.</p> -->
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <h3>About</h3>
                    <ul>
                       <p>RMC is the civic body that governs the city of Rajamahendravaram in the Indian state of Andhra Pradesh. Constituted as a third grade Municipality in 1865, Rajamahendravaram town has grown fast to the level of selection grade Municipality in the year 1980.</p>

                    </ul>
                </div>
            </div>


            <div class="col-lg-3 col-md-6">
                <div class="footer-widget pl-40">
                    <div class="footer-widget">
                        <h3>Find Us</h3>
                        <p class="find-text"> Mon - Sun : 09:00AM - 10:00PM.</p>
                        <ul class="find-us">
                            <li>
                                <i class="icofont-location-pin"></i> KambalaCheruvu Park,Rajamahendravaram
                            </li>
                            <li>
                                <i class="icofont-phone"></i>
                                <a href="tel:+919494060060">9494060060</a>
                            </li>

                        </ul>
                    </div>
                </div>

                <a href="https://amaravathisoftware.com" target="_blank"  style="color:white">Design & Developed By Amaravathi Softwares</a>
            </div>
            </div>

        </div>


    <!-- <div class="bottom-footer">
        <div class="container">
            <div class="row  align-items-center">
                <div class="col-lg-6">
                    <div class="footer-social">
                        <ul>
                            <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                </div>
            </div>
        </div>
    </div> -->
</footer>

<div class="top-btn">
    <i class="icofont-scroll-long-up"></i>
</div>
