<div class="header-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12">
                <div class="header-widget">
                    <ul>
                        <li>
                            <i class="icofont-clock-time"></i> Mon - Sun :
                            09:00AM - 10:00PM
                        </li>
                        <li>
                            <i class="icofont-location-pin"></i>
                            Rajamahendravaram Muncipal Corporation
                        </li>
                        <li>
                            <i class="icofont-phone"></i>
                            <a href="tel:+ 919494060060"> 9494060060</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="header-social text-end">
                    <ul>
                        <li>
                            <a target="_blank" href="https://www.facebook.com/officeRMC/"><i
                                    class="icofont-facebook"></i></a>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.instagram.com/rmcoffice/?hl=en">
                                <i class="icofont-instagram"></i>
                            </a>
                        </li>                    
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="#home" class="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
    <a class="navbar-brand" href="#home">
        <div class="d-flex align-items-center">
            <img style="width: 30%" src="assets/img/LOGO (7).png" alt="logo" />           
        </div>
    </a>
</nav>
        </div>
    </div>
</div>
<div class="home-style-two home-bg">
    <div #home class="container" id="home">
        <div class="home-text-two">
        </div>
        <div class="home-image">
            <img src="assets/img/Group 105 (2).png.png" style="width: 496px" alt="home image" />
        </div>
    </div>
</div>
<br><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

<div class="mobile-content">
<div class="mobile-nav">
    <div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2 style="text-align: center;">kambala cheruvu</h2>
                            <p>Park Ticket Booking</p>
                            <marquee><b style="color:red">Note</b> : <b style="color:red;" >when payment is done please get back to this site and  download the ticket</b></marquee>
                        </div>
                        <div class="contact-form">
                            <form>
                                <div class="form-group row">
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="name" style="color: black;">Name<span style="color:red">*</span></label>
                                        <input type="text" [(ngModel)]="name" name="name" id="name"
                                            class="form-control" required [disabled]="formDisabled"/>
                                    </div>
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="phoneNumber" style="color: black;">Mobile Number<span style="color:red">*</span></label>
                                        <input type="text" [(ngModel)]="phoneNumber" name="phoneNumber" id="phoneNumber"
                                            class="form-control"   minlength="10" maxlength="10"  (keypress)="numericOnly($event)" required [disabled]="formDisabled"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="adultCount" style="color: black;">Adults<span style="color:red">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend" class="tet">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="decrementAdults()"
                                                    class="plus-minus-button btn btn-warm" class="btn btn-warm"[disabled]="formDisabled" >-</button>
                                            </div>
                                            <input type="number" [(ngModel)]="adultsCount" name="adultCount"
                                                id="adultCount" class="form-control" placeholder="Adult" min="0" [disabled]="formDisabled"
                                                style="margin-left: 5px; margin-right: 5px;border-radius: 9px;" readonly />
                                            <div class="input-group-append">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="incrementAdults()"
                                                    class="plus-minus-button btn btn-warm"  class="btn btn-warm" [disabled]="formDisabled" >+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="childCount" style="color: black;">Children<span style="color:red">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="decrementChildren()"
                                                    class="plus-minus-button btn btn-warm"  class="btn btn-warm"  [disabled]="formDisabled">-</button>
                                            </div>
                                            <input type="number" [(ngModel)]="childrenCount" name="childCount" [disabled]="formDisabled"
                                                id="childCount" class="form-control" placeholder="Child" min="0"
                                                style="margin-left: 5px; margin-right: 5px;border-radius: 9px;"readonly  />
                                            <div class="input-group-append">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="incrementChildren()"
                                                    class="plus-minus-button btn btn-warm"  class="btn btn-warm" [disabled]="formDisabled">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="total-cost">
                                    <div class="left">
                                        <p><b>Total Adults Cost:</b></p>
                                    </div>
                                    <div class="right">
                                        <p>Rs. {{ totalAdultsCost }} </p>
                                    </div>
                                </div>
                                <div class="total-cost">
                                    <div class="left">
                                        <p><b>Total Children Cost:</b></p>
                                    </div>
                                    <div class="right">
                                        <p>Rs. {{ totalChildrenCost }} </p>
                                    </div>
                                </div>
 <hr>
                                <div class="total-cost">
                                    <div class="left">
                                        <p><b>Grand Total:</b></p>
                                    </div>
                                    <div class="right">
                                        <p>Rs. {{totalAdultsCost + totalChildrenCost }}</p>
                                    </div>
                                </div>
                                <hr>
                                <div class="row" style="text-align: center;">
  <div class="col-lg-12" *ngIf="!paymentBtn">
    <button type="submit" class="btn btn-primary" (click)="vehicleindata()">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </button>
  </div>
  <form #form id="nonseamless" method="post" name="redirect"
    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
    *ngIf="paymentBtn">
    <!-- <div class="alert alert-danger" role="alert"> {{PaymentMsg}} </div> -->
    <input type="hidden" id="encRequest" name="encRequest" value="{{sscode}}">
    <input type="hidden" name="access_code" id="access_code" value="AVLN23KJ19BL89NLLB">
    <button type="submit" class="btn btn-success" (click)="form.submit()">Confirm</button>
  </form>
</div>
 <hr>
 <br>
           <p><b style="color:red">Note</b> : <b>when payment is done please get back to this site and  download the ticket</b></p>          
        <br>
    <br>      </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</div>
<div class="desktop-content">
<div class="contact-section">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="contact-img">
                        <img src="assets/img/PARK (5).png" alt="contact image" />
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2>kambala cheruvu</h2>
                            <p>Park Ticket Booking</p>
                            <marquee><b style="color:red">Note</b> : <b style="color:red;" >when payment is done please get back to this site and  download the ticket</b></marquee>
                        </div>
                        <div class="contact-form">
                            <form>
                                <div class="form-group row">
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="name" style="color: black;">Name<span style="color:red">*</span></label>
                                        <input type="text" [(ngModel)]="name" name="name" id="name"
                                            class="form-control" required [disabled]="formDisabled"/>
                                    </div>
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="phoneNumber" style="color: black;">Mobile Number<span style="color:red">*</span></label>
                                        <input type="text" [(ngModel)]="phoneNumber" name="phoneNumber" id="phoneNumber"
                                            class="form-control" required minlength="10" maxlength="10"  (keypress)="numericOnly($event)" [disabled]="formDisabled"/>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="adultCount" style="color: black;">Adults<span style="color:red">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend" class="tet">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="decrementAdults()"
                                                    class="plus-minus-button btn btn-warm" class="btn btn-warm"[disabled]="formDisabled" >-</button>
                                            </div>
                                            <input type="number" [(ngModel)]="adultsCount" name="adultCount"
                                                id="adultCount" class="form-control" placeholder="Adult" min="0" [disabled]="formDisabled"
                                                style="margin-left: 5px; margin-right: 5px;border-radius: 9px;" readonly />
                                            <div class="input-group-append">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="incrementAdults()"
                                                    class="plus-minus-button btn btn-warm"  class="btn btn-warm" [disabled]="formDisabled" >+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="text-align: center;">
                                        <label for="childCount" style="color: black;">Children<span style="color:red">*</span></label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="decrementChildren()"
                                                    class="plus-minus-button btn btn-warm"  class="btn btn-warm"  [disabled]="formDisabled">-</button>
                                            </div>
                                            <input type="number" [(ngModel)]="childrenCount" name="childCount" [disabled]="formDisabled"
                                                id="childCount" class="form-control" placeholder="Child" min="0"
                                                style="margin-left: 5px; margin-right: 5px;border-radius: 9px;"readonly  />
                                            <div class="input-group-append">
                                                <button style="border-color: aliceblue;background-color:#93bff78f" (click)="incrementChildren()"
                                                    class="plus-minus-button btn btn-warm"  class="btn btn-warm" [disabled]="formDisabled">+</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="total-cost">
                                    <div class="left">
                                        <p><b>Total Adults Cost:</b></p>
                                    </div>
                                    <div class="right">
                                        <p>Rs. {{ totalAdultsCost }} </p>
                                    </div>
                                </div>
                                <div class="total-cost">
                                    <div class="left">
                                        <p><b>Total Children Cost:</b></p>
                                    </div>
                                    <div class="right">
                                        <p>Rs. {{ totalChildrenCost }} </p>
                                    </div>
                                </div>
                                <hr>
                                <div class="total-cost">
                                    <div class="left">
                                        <p><b>Grand Total:</b></p>
                                    </div>
                                    <div class="right">
                                        <p>Rs. {{totalAdultsCost + totalChildrenCost }}</p>
                                    </div>
                                </div>
                                <br>


<div class="row" style="text-align: center;">
  <div class="col-lg-12" *ngIf="!paymentBtn">
    <button type="submit" class="btn btn-primary" (click)="vehicleindata()">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </button>
  </div>
  <form #form id="nonseamless" method="post" name="redirect"
    action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
    *ngIf="paymentBtn">
    <!-- <div class="alert alert-danger" role="alert"> {{PaymentMsg}} </div> -->
    <input type="hidden" id="encRequest" name="encRequest" value="{{sscode}}">
    <input type="hidden" name="access_code" id="access_code" value="AVLN23KJ19BL89NLLB">
    <button type="submit" class="btn btn-success" (click)="form.submit();sumit2()">Confirm</button>
  </form>
</div>
               <p><b style="color:red">Note</b> : <b>when payment is done please get back to this site and  download the ticket</b></p>   
            <br>              </form>

                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
<br><br><br><br><br><br><br><br><br><br>

<h1 style="text-align: center;color:black;" class="valud">For Any Queries Get In Touch With Us</h1>
<div class="contact-section2">
    <div class="container">
        <div class="contact-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-lg-6">
                    <div class="contact-img">
                        <img src="assets/img/contact.png" alt="contact image" />
                    </div>
                </div>
                <div class="col-lg-6 col-lg-6">
                    <div class="contact-text">
                        <div class="section-head">
                            <h2 style="text-align: center;">For Any Queries</h2>
                        </div>
                        <div class="contact-form">
                            <form [formGroup]="addcontactsform">
                                <div class="form-group">
                                    <label for="name" style="color: black;">Name</label>
                                    <input type="text" name="name" id="name" class="form-control" formControlName="name"  />
                                </div>
                                <div class="form-group">
                                    <label for="phoneNumber" style="color: black;">Mobile Number<span style="color: red">*</span></label>
                                    <input type="text" name="phoneNumber" id="phoneNumber" class="form-control"
                                        required minlength="10" maxlength="10" (keypress)="numericOnly($event)" formControlName="number" required  />
                                </div>
                                <div class="form-group">
                                    <label for="message" style="color: black;">Message</label>
                                    <textarea name="message" id="message" class="form-control" formControlName="message" ></textarea>
                                </div>
                                <div class="form-group" style="text-align: center;margin-top: 16px;" >
                                    <button type="submit" class="btn btn-primary" (click)="addcontactssform()">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




