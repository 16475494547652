import { Component, OnInit } from "@angular/core";
import { HomeService } from "../../pages/home.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
declare var Razorpay: any; //rezorpay
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
    adultsCount: number = 0;
    childrenCount: number = 0;
    costPerAdult: number = 50;
    costPerChild: number = 30;
    totalAdultsCost: number = 0;
    totalChildrenCost: number = 0;
    parentamount: number = 0;
    childamount: number = 0;
    name: string = "";
    phoneNumber: string = "";
    addcontactsform: FormGroup;
    constructor(public service: HomeService, private formBuilder: FormBuilder) {
        if (localStorage.getItem("active") == "1") {
            location.reload();
            localStorage.setItem("active", "0");
        }
    }
    ngOnInit(): void {
        this.updateTotalCosts();
        // this.get();
        this.getentryticketrate();
        this.addcontactsform = this.formBuilder.group({
            name: [""],
            number: ["", [Validators.required]],
            message: [""],
        });
    }
    getentryticketrate() {
        this.service.getentryticketrate().subscribe((res: any) => {
            console.log("ffhsd", res);
            this.parentamount = res.data[1].amount;
            this.childamount = res.data[0].amount;
        });
    }
    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }
    incrementAdults() {
        this.adultsCount++;
        this.updateTotalCosts();
    }
    decrementAdults() {
        if (this.adultsCount > 0) {
            this.adultsCount--;
            this.updateTotalCosts();
        }
    }
    incrementChildren() {
        this.childrenCount++;
        this.updateTotalCosts();
    }
    decrementChildren() {
        if (this.childrenCount > 0) {
            this.childrenCount--;
            this.updateTotalCosts();
        }
    }
    updateTotalCosts() {
        // Assuming cost per adult and child are 10 rs and 5 rs respectively
        this.totalAdultsCost = this.adultsCount * this.parentamount;
        this.totalChildrenCost = this.childrenCount * this.childamount;
        // this.totalAdultsCost = this.adultsCount*2
        // this.totalChildrenCost = this.childrenCount*1
    }
    // options: any;
    sscode: any;
    PaymentMsg: any;
    paymentBtn: boolean = false;
    randomnumber: any;
    // get() {
    //     this.service.getlastid().subscribe((res: any) => {
            
    //     });
    // }

sumit2(){
this.paymentBtn = false;
}


    formDisabled: boolean = false;
    vehicleindata() {

         this.service.getlastid().subscribe((res: any) => {
if(res.status==200){
          var count = Math.floor(1001 + Math.random() * 9000);
        var randomnumber = res.data[0].id;
        var number = randomnumber + 1;
        this.randomnumber = "RMC" + number + "KM" + count;
  
        if (this.phoneNumber.length !== 10|| (!this.adultsCount && !this.childrenCount)||!this.name) {
            alert("Please fill in all required fields.");
            return;
        } else {
            const totalCost = this.totalAdultsCost + this.totalChildrenCost;
            let request =
                `merchant_id=2944874&order_id=` +
                this.randomnumber +
                `&currency=INR&amount=` +
                totalCost +
                `&billing_name=Customer&billing_address=Kamabalacheruvu&billing_city=Rajamundry&merchant_param1=` +
                this.adultsCount +
                `&merchant_param2=` +
                this.childrenCount +
                `&merchant_param3=` +
                this.parentamount +
                `&merchant_param4=` +
                this.childamount +
                `&merchant_param5=` +
                this.phoneNumber +
                `&delivery_state=` +
                this.name +
                `&redirect_url=https://kcprmc.com:5845/ccavResponseHandler&cancel_url=https://kcprmc.com:5845/ccavResponseHandler&language=EN`;
            console.log(request);
            this.service.NodekitPay(request).subscribe((res: any) => {
                this.sscode = res.data;
                localStorage.setItem("active", "1");
                this.paymentBtn = true;
                this.formDisabled = true;
                
            });
        }
    }else{
        alert("please check details")
    }
  });

    }
    numericOnly(event): boolean {
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        return result;
    }
    addcontactssform() {
        if (this.addcontactsform.invalid) {
            alert("please fill details");
        } else {
            this.service.addcontactsform(this.addcontactsform.value).subscribe(
                (res: any) => {
                    if (res.status == 200) {
                        alert("Successfully Updated");
                    }
                    this.addcontactsform.reset();
                },
                (error) => {
                    this.addcontactsform.reset();
                }
            );
        }
    }
    
}
